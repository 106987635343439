.activity-compact{
    display: flex;
    width: 15rem;
    border: 0.1rem solid $color-23;
    align-items: center;
    padding: 2rem;

    .activity-icon{
        margin-right: 1.5rem;
        img{
            width: 2.5rem;
        }
    }

    .activity-title{
        font-family: 'R-black';
        font-size: $default;
        color: $color-5;
    }

    .activity-info{
        font-size: $default;
    }

    &.unbordered{
        border: none;
        width: 80%;
        margin: 0 auto;
    }

    &.analysis{
        width: 20rem!important;
    }
}