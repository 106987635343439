.patient-activities {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .activity-compact {
        border: none;
        width: 33.3%;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(5) {
            border-right: 0.1rem solid lightgray;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
            border-top: 0.1rem solid lightgray;
        }
    }
}