.appointment{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem 0;
    border-bottom: 0.2rem dotted $color-16;

    &:last-child{
        border-bottom: none;
    }

    .info-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
    }

    .info{
        .name{
            color: $color-1;
            font-size: $default;

            &.colorless{
                color: #000
            }
        }
    }

    .time{
        p{
            margin-right: 1rem;
        }
    }
    
}