 .form-group{
    input:focus ~ .custom-label{
        opacity: 1;
    }

    input:placeholder-shown ~ .custom-label{
        opacity: 0;
    }

}

.custom-label{
    position: absolute;
    top: 0;
    opacity: 1;
    @include transition-default;
}

.select-group{
    position: relative;

    select{
        padding-left: 8rem;
        cursor: pointer;


        option{
            text-align: center;
        }
    }

    .pre-select{
        position: absolute;
        width: 5rem;
        top: 0.6rem;
        left: 3rem;
        z-index: 3;
    }
}

.form-control[readonly]{
    background-color: #fff;
}

.inline-error{
    font-size: $small-extra;
    font-weight: 'R-medium';
    color: $color-6;
    margin-bottom: 5rem;
}