fieldset {
    &.form-group {
        margin-bottom: 0;
    }
}

.form-group {
    margin-bottom: $m-large;
    padding-top: $m-base;
    position: relative;
    text-align: left;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        background-size: 3rem;
        background-repeat: no-repeat;
        background-position: center;
    }

    /*&:last-child {
      margin-bottom: 0;
    }*/

    label {
        font-family: "R-regular";
        font-size: $small;
        color: $color-1;
    }

    .custom-file-input {
        margin-top: 3rem;
    }

    .custom-file-label {
        @include radius-default;
        margin-bottom: 0;
        height: 6rem;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            cursor: pointer;
        }

        &:after {
            content: "";
            // background: url("../../../images/icons/arrow-right-blue.png") no-repeat center;
            background-color: $color-12;
            height: 6rem;
            width: 6rem;
            @include radius-default;
            display: block;
        }
    }
}

.col-form-label {
    font-family: "R-bold";
    margin: 0 0 $m-base;
    padding: 0;
    line-height: initial;
}

input[type="radio"] ~ .required {
    &:after {
        content: "" !important;
    }
}

.custom-checkbox,
.custom-radio {
    display: flex;
    align-items: center;
    //padding-left: $m-notable;
    margin-right: $m-base;
    z-index: 9;
}

.form-group label.required::after,
.form-group legend.required::after {
    content: " *";
}

.form-control::-webkit-input-placeholder {
    color: $color-19;
}

.form-control:-moz-placeholder {
    color: $color-19;
    opacity: 1;
}

.form-control::-moz-placeholder {
    color: $color-19;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: $color-19;
}

.form-control::-ms-input-placeholder {
    color: $color-19;
}

.form-control::placeholder {
    color: $color-19;
}

.no-asterisk .form-group label.required::after,
.no-asterisk .form-group legend.required::after {
    content: "";
}

legend.required + div label.required::after {
    content: "";
}

.custom-checkbox .custom-control-label,
.custom-radio .custom-control-label {
    font-family: "R-regular";
    font-size: $default;
    margin-bottom: 0;
    margin-left: 1.5rem;
    color: $color-19;
    user-select: none;

    &::before {
        top: 0;
        left: -$m-notable;
        width: 2rem;
        height: 2rem;
        pointer-events: auto;
        border: 0.1rem solid $color-4 !important;
        background-color: #ffffff;
    }

    &::after {
        top: 0.2rem;
        left: -3rem;
        width: 2rem;
        height: 2rem;
    }

    &.required {
        &:after {
            content: "";
        }
    }
}

.custom-checkbox .custom-control-label {
    &::before {
        border-radius: 0.6rem;
    }
}

.checkbox-bordered {
    .form-group {
        height: auto;
        padding: 1rem 2rem;
        border: 0.1rem solid $color-4;
        border-radius: 1.2rem;
        margin: 2rem 0;
    }
}

.custom-control-input {
    min-height: 1.8rem;
    min-width: 1.8rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: $color-1 !important;
    background-color: #ffffff !important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1.2rem 1.2rem;
}

.custom-control-input:checked ~ .custom-control-label::after {
    background-image: none !important;
    background-color: $color-1;
    content: "";
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 0.4rem;
    left: -2.7rem;
    top: 0.3rem;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none !important;
    outline: 0;
}

.form-check {
    padding-left: $m-middle;
    display: inline-block;
    margin-right: $m-middle;

    .form-check-input {
        margin-top: 0.55rem;
        margin-left: -$m-middle;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0 3rem white inset !important;
}
/* Change Autocomplete styles in Chrome*/

input:-webkit-autofill,
select:-webkit-autofill,
textarea:-webkit-autofill {
    //border: 0.1rem solid $color-4;
    -webkit-text-fill-color: $color-19;
    -webkit-box-shadow: 0 0 0 1000px $color-2 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.form-control {
    font-size: $medium;
    height: auto;
    padding: 0.8rem;
    background-color: #ffffff;
    border: none;
    border-bottom: 0.2rem solid $color-20;
    font-family: "R-regular";
    color: $color-19;
    @include transition-default;
    &.search {
        background-image: url("../../images/icons/search-input.png");
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 2rem;
        padding-right: 2.5rem;
    }
    &:focus {
        @include transition-default;
        outline: none;
        background-color: #ffffff;
        color: $color-19;
        border-bottom: 0.2rem solid $color-1 !important;
        box-shadow: none;

        &::placeholder {
            color: $color-21 !important;
        }
    }
    &:hover {
        border-bottom-color: $color-11;
    }

    &.error {
        color: $color-6;
        border-bottom: 0.2rem solid $color-6;
    }

    &.is-invalid {
        border-color: $color-9;
    }

    &[disabled] {
        opacity: 0.4;

        &:hover {
            cursor: not-allowed;
        }
    }

    &:placeholder-shown {
        // background-color: $color-2;
        // color: $color-4;
        // padding: 1rem 2rem;
    }
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    opacity: 0.4;
}

.select2-search--dropdown,
.select2-selection,
.select2-container--default .select2-results > .select2-results__options {
    background-color: $color-2 !important;
    border: 0.1rem solid $color-2 !important;
}

.select2-selection {
    font-size: $default;
    height: auto !important;
    padding: 1.1rem 1.9rem !important;
    background: #fff !important;
    border: 0.1rem solid $color-4 !important;
    font-family: "R-regular";
    color: $color-1 !important;
    @include transition-default;
    border-radius: 1.2rem !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    color: $color-4 !important;

    &:focus {
        @include transition-default;
        background-color: #ffffff;
        color: $color-1;
        border: 0.1rem solid $color-4;
        box-shadow: none;
    }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    min-height: 2.8rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 50%;
    transform: translateY(-50%);
}

.select2-container--default .select2-results > .select2-results__options {
    color: $color-4 !important;
}

select {
    position: relative;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-family: "R-regular" !important;
    appearance: none;
    background-size: 1.8rem, 6rem 100%;
    background-repeat: no-repeat;
    background: #fff;
    border: 0.2rem solid $color-1;
    border-radius: 0.3rem;
    margin: 0 2rem;
    padding: 0.4rem 1rem;
    &.form-control {
        // background: url("../../../images/icons/arrow-right-blue-down.png") no-repeat right center;
    }

    option {
        padding: 1rem;
        font-family: "R-regular" !important;
    }
}

.form-error-icon {
    background: $color-9;
}
.form-error-message {
    color: $color-9;
    font-family: "R-regular";
}
