.button{
    border-radius: .4rem;
    padding: .5rem 1rem;
    background: $color-1;
    color: #fff;
    border: 0.1rem solid $color-1;

    svg{
        margin-right: 1rem;
    }

    &.secondary{
        display: block;
        background: $color-5;
        padding: 1rem 2rem;
        border: 0.1rem solid $color-5;

        &.left{
            margin-left: auto;
        }

        &.flexed{
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            padding: 0.5rem 1.8rem;

            .icon-img{
                margin-right: .5rem;
            }
        }
    }
    
    &.big{
        padding: 1rem 3rem;
    }

    &.deny{
        background: $color-6;
        border: 0.1rem solid $color-6;
        
        &.outline{
            background: #fff;
            color: $color-6;
            border: 0.2rem solid $color-6;
        }
    }

    &.bigger{
        padding: 1rem 6rem;
        margin: 1.5rem;
        font-size: $medium;
    }

    &.outlined{
        background: #fff;
        color: $color-19;
    }

    &:hover{
        cursor: pointer;
    }
}