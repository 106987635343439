.wrapper-header-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 0.2rem solid $color-11;
    margin-bottom: 4rem;

    .col-left {
        padding: 3rem 0;
        display: inline-flex;
        align-items: center;

        .reduce-menu {
            margin-right: 2rem;
        }

        .form-group {
            margin-bottom: 0;

            input {
                margin: 0;
            }
        }
    }

    .col-right {
        padding: 3rem 0;
        display: inline-flex;
        align-items: center;

        .wrapper-notify {
            margin-right: 4rem;
        }
    }
}
