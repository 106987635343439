.context-appointment{
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.2rem solid $color-1;
    cursor: pointer;

    .appointment-inner{
        flex-grow: 1;
        display: flex;
        align-items: flex-start;
    }

    &:last-child{
        border-bottom: none;
    }

    .avatar{
        margin-right: 2.5rem;
    }

    .name{
        font-size: $medium-large;
    }
    .adder{
        font-size: $medium;
    }

    .time{
        font-size: $big;
        font-family: 'R-medium';
        margin-left: auto;
    }

    .confirmed{
        color: $color-1;
    }

    .delete-button{
        position: absolute;
        right: 0rem;
        bottom: 1rem;
    }
 
}