.context-menu-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 898;
}

.context-menu {
    position: absolute;
    top: 0;
    left: 0;
    height: 40rem;
    width: 50rem;
    max-width: 90%;
    padding: 3rem;
    border-radius: 0.8rem;
    box-shadow: 0 0.1rem 1.6rem 0 $color-13;
    background-color: rgba(255, 255, 255, 0.97);
    z-index: 899;

    .button {
        display: block;
        margin: 3rem auto 0 auto;
    }

    &.big {
        width: 80rem;
        padding-bottom: 2rem;
        height: fit-content;
        max-height: 40rem;
        overflow-y: auto;
    }
}

.appointments-wrapper {
    height: 28rem;
    overflow-y: scroll;
    margin-right: -3rem;
    padding-right: 3rem;

    .time {
        display: flex;
        align-items: center;

        .icon-img {
            width: 2.7rem;
            height: 2.7rem;
            margin-left: 1.5rem;
        }
    }
}



@media screen and (max-width: 600px) {
    .context-menu-wrapper {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .context-menu {
        transform: translate(-50%, -50%);
    }
}