.modal-wrapper{
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.5);
    z-index: 998;

    &.visible{
        display: flex;
    }

    .modal-bg{
        position: fixed;
        height: 100vh;
        width: 100vw;
        left: 0;
        top: 0;
        z-index: 998;
    }

    .rounded-box{
        position: relative;
        width: 80%;
        max-width: 55rem;
        overflow-y: auto;
        padding: 3rem;
        z-index: 999;

        .footer-logo{
            height: 3rem;
        }
    }
}

.modal-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    padding: 0;
    border-bottom: none;

    &.with-img{
        height: 18rem;
        margin-bottom: -1rem;
        @include bph(xl) {
            height: 15rem;
        }
        @include bph(lg) {
            height: 10rem;
        }
    }

    img{
        position: absolute;
        width: 100%;
        max-height: 18rem;
        top: 0;
        left: 0;
    }
}

.modal-title{
    font-family: 'R-medium';
    font-size: $big;
    color: $color-1;

}


.modal-close{
    height: 3.4rem;
    width: 3.4rem;
    background: $color-5;
    color: #fff;
    border: none;
    border-radius: 50%;

    &.absolute{
        position: absolute;
        right: 2rem;
        top: 2rem;
    }
}

.section-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'R-medium';
    font-size: $big;
    color: $color-4;
    border-bottom: 0.1rem solid $color-19;
    margin-bottom: 1rem;
}

.user-wrapper{
    display: flex;
    margin-bottom: 2rem;
    .avatar{
        margin-right: 2.5rem;
    }
    .name{
        margin-bottom: 1.5rem;
        font-size: $medium-large;
    }
}

.modal-content{
    border: none!important;

    ul{
        max-height: 80vh;
        overflow-y: auto;
    }
}

.modal-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;

    button{
        margin: 0 1.5rem;
        font-size: $large;
    }

    svg{
        height: 2rem;
        width: 2rem;
        margin-right: 2rem;
    }
}

.text-area-modal{
    textarea{
        width: calc(100% - 4rem);
        height: 10rem;
        margin: 0 auto 3rem auto;
    }
}

.event-footer{
    text-align: center;
    padding: 1.7rem 0;
    border-top: 0.1rem solid $color-22;
}
