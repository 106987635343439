::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0.5rem rgba(0,0,0,0.3);
    @include radius-default;
    background-color: $color-3;
}

::-webkit-scrollbar {
    width: 0.4rem;
    background-color: $color-3;
}

::-webkit-scrollbar-thumb {
    @include radius-default;
    -webkit-box-shadow: inset 0 0 0.5rem rgba(0,0,0,.3);
    background-color: $color-2;
}
