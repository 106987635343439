.activity-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.2s;

    .activity-title {
        font-family: "R-bold";
        font-size: $default;
        margin-bottom: 1rem;
    }
    .activity-info {
        position: relative;
        font-family: "R-bold";
        font-size: $big;
        margin-bottom: 2rem;
        color: $color-1;

        &.warning {
            color: $color-14;
        }

        &.danger {
            color: $color-6;
        }

  
    }
    .activity-timing {
        font-size: $small-extra;
    }
    .activity-icon {
        img {
            width: 5rem;
        }
    }

    &:hover {
        transform: scale(1.01);
    }
}

.activity-box-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .activity-box {
        width: calc(33.3% - 1.5rem);
        @include bp(xl) {
            width: calc(50% - 1.5rem);
        }
        @include bp(md) {
            width: 100%;
        }
    }
}


.activity-info {
    position: relative;
    &.positive,
    &.negative {
        padding-right: 2rem;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            height: 2rem;
            width: 2rem;
            background-image: url(../../images/icons/arrow.svg);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    
    &.negative{
        &::after{
            background-image: url(../../images/icons/arrow-red.svg);
            transform: rotate(180deg)
        }
    }
}