.avatar{
    min-height: 4rem;
    min-width: 4rem;
    height: 4rem;
    width: 4rem;
    background-image: url(../../images/icons/user-circle.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &.gender-f{
        background-image: url(../../images/icons/user-circle-f.png);
    }

    &.gender-m{
        background-image: url(../../images/icons/user-circle-m.png);
    }

    &.gender-x{
        background-image: url(../../images/icons/user-circle-x.png);
    }
}