.news-card{
    padding: 0;
    margin-bottom: 2rem;
    @include radius-default;
    height: calc(100% - 2rem);
    &:hover {
        cursor: pointer;
    }

    .card-header{
        width: 100%;
        height: 20rem;
        padding: 0;
        img{
            height: 100%;
            width: 100%;
        }
    }

    .card-content{
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .title{
        color: $color-1;
        font-family: 'R-medium';
        font-size: $big;

    }

    .excerpt{
        margin: 1rem 0;
        font-size: $medium;
    }

    .article{
        &.inner{
            height: 30rem;
            overflow-y: auto;
            @include bph(xl) {
                height: 20rem;
            }
            @include bph(lg) {
                height: 15rem;
            }
        }
    }

    .button-wrapper{
        text-align: right;
        margin-top: auto;
    }

    &.max{
        max-width: 50rem;
    }

}
