.system-message {
    position: fixed;
    width: 65rem;
    max-width: 70%;
    top: 10rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1005;
    color: #fff;
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;


    &.visible{
        opacity: 1;
    }

    &.positive {
        background-color: $color-1;
    }

    &.negative {
        background-color: $color-6;
    }
}
