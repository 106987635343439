.colored-title{
    font-family: 'R-bold';
    font-size: $medium;
    color: $color-5;

    &.green{
        color: $color-1;
    }

    &.regular{
        font-family: 'R-regular';
    }
}