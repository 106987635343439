.vertical-calendar{
    height: 100%;
    overflow-y: auto;
    margin-bottom: 3rem;

    .calendar-days{
        width: calc(100% + 3.8rem);
        margin: 0 -1.9rem;
        padding: 1rem;
        background: $color-18;
        display: flex;
        align-items: center;
        border-radius: 1rem;

        svg{
            margin: 0 1rem;
            height: 2rem;
            width: 2rem;
            z-index: 2;
            cursor: pointer;
        }

        .days-wrapper{
            flex-grow: 1;
           display: flex;
           overflow-x: hidden;
        }

        .day{
            min-width: 4rem;
            padding: 1rem 0;
            display: flex;
            flex-direction: column-reverse;
            text-align: center;
            cursor: pointer;


            .month-day{
                font-size: $default;
            }
            .week-day{
                color: $color-17;
                font-size: $smaller;
            }

            &.active{
                background: #fff;
                border-radius: 1rem;
                box-shadow: 0 .1rem .8rem $color-12;
            }
        }
    }

    .title{
        font-family: 'R-medium';
        font-size: $medium;
    }

    .month{
        color: $color-15;
        font-size: $small;
        border-bottom: 0.2rem dotted $color-15;
        margin-bottom: 2rem;
    }

    // .time{
    //     svg{
    //         margin-left: 1rem;
    //     }
    // }

     .time{
        display: flex;
        align-items: center;
        
        p{
            line-height: 0;
            font-family: 'R-medium'
        }

        .icon-img{
            width: 1.5rem;
            height: 1.5rem;
            margin-left: 1.5rem;
        }
    }
}