.wrapper-appstore {
    text-align: center;
    padding: 2rem;
    border-radius: .4rem;
    background-color: $color-9;
    h5 {
        margin-bottom: 2.5rem;
    }
}

.link-store {
    background-color: #ffffff;
    border-radius: .4rem;
    width: 5rem;
    height: 5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 1.5rem;

    .logo-store {
        width: 3rem;
        flex: 0 0 3rem;
    }
}
