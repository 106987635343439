.react-calendar{
    border: none;

    &.range-picker{
        width: 100%;
    }

    &.absolute{
        position: absolute;
        width: 80%;
        left: 1rem;
        bottom: 1rem;
        box-shadow: 0 0.2rem 1rem $color-13;

        .react-calendar__navigation{
            background: transparent;

            .react-calendar__navigation__label__labelText{
                color: $color-1;
                font-family: 'R-bold';
            }

            .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button{
                display: none;
            }
            .react-calendar__navigation__prev-button, .react-calendar__navigation__next-button{
                height: 2.5rem;
                width: 2.5rem;
                min-width: unset;
                margin: 0.8rem;
                box-shadow: 0 0.2rem 1rem $color-13;
                font-size: $medium-large;
                color: $color-24;
                
            }
        }

        .react-calendar__viewContainer{
            border: 0.1rem solid $color-23;
            margin: 1rem;
        }

        .react-calendar__month-view__weekdays{
            background: $color-28;
            color: $color-27;
        }
        .react-calendar__tile{
            border: 0.1rem solid $color-29;
        }

        .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus{
            background: $color-1;
            color: #fff;
        }
        .react-calendar__tile--active{
            background: $color-1;
        }
    }

    .react-calendar__navigation{
        background: $color-8;
        border-radius: 0.8rem;
    }

}