.custom-chart-tooltip {
    padding: 0.9rem 1.2rem;
    border: 0.1rem solid $color-24;
    border-radius: 0.4rem;
    background: #fff;
    z-index: 500;

    .chart-square {
        display: inline-block;
        height: 1rem;
        width: 1rem;
        margin-right: 1rem;
        background-color: $color-4;
        
        &.Aderenza {
            background-color: $color-6;
        }
        &.Umore {
            background-color: $color-1;
        }
        &.Traguardi {
            background-color: $color-17;
        }
        &.Questionari {
            background-color: $color-5;
        }
    }
}
