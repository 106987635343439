.goal-add-wrapper{

    label{
        display: block;
        color: $color-2;
        font-family: 'R-medium';
        text-align: center;
        font-size: $medium;
        margin-bottom: 0.5rem;
    }
    
    input,select,textarea{
        display: block;
        margin: 0 auto;
        border: 0.2rem solid $color-1;
    }

    input{
        padding: 0.4rem 1rem;
    }

    textarea{
        width: 100%;
    }
}