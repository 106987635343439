.medication-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 20rem;
    box-shadow: 0 0.1rem 0.8rem $color-13;
    padding: 1rem;
    margin: 1rem 0;
    @include radius-default;
    cursor: pointer;
    border: 0.1rem solid #fff;

    .medication-img {
        img {
            height: 4rem;
        }
    }

    .medication-name {
        font-family: "R-bold";
        font-size: $default;
        word-break: break-word;
    }

    .medication-posology {
        font-size: $small-extra;
    }

    .medication-adeherence {
        font-family: "R-bold";
        margin-left: auto;
    }

    &.type-1 {
        .medication-name {
            color: $color-25;
        }
    }
    &.type-2 {
        .medication-name {
            color: $color-26;
        }
    }

    &.selected {
        border: 0.1rem solid $color-22;
    }
}
