.info-box{
    margin-bottom: 2.5rem;

    .info-title{
        font-family: 'R-medium';
        font-size: $medium;
        margin-bottom: 1rem;
    }

    .info-wrapper{
        display: grid;
        grid-template-columns: 1fr 3fr;
    }


    .user-name{
        font-family: 'R-bold';
        font-size: $medium;
        margin-bottom: .4rem;
    }

    hr{
        margin: 1.3rem 0;
        border-top: .2rem dotted $color-15;
        background-color: transparent;
        opacity: 1;
    }
    .footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .event-time{
        font-family: 'R-medium';
    }
}