.wrapper-notify {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    flex: 0 0 3rem;
    background: url("../../images/icons/bell.png") no-repeat center;
    background-size: contain;

    &:hover {
        cursor: pointer;
    }

    .notify-circle {
        position: absolute;
        top: -0.5rem;
        right: -0.3rem;
        width: 1.8rem;
        height: 1.8rem;
        border: 0.2rem solid #ffffff;
        background-color: $color-1;
        display: none;
        border-radius: 50%;

        &.active {
            display: block;
        }
    }
}
