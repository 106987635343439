.patient-box {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    h2 {
        margin: 1.5rem 0;
    }

    .divider {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.1rem solid $color-22;
        padding-bottom: 1rem;
    }

    &.search {
        transition: all 0.3s;
        &:hover {
            background-color: $color-1;
            color: #fff;
            box-shadow: 0 0.2rem 0.9rem rgb(20 46 110 / 30%);
            
            .avatar{
                background-image: url(../../images/icons/user-circle-w.png)
            }
        }

    }
}
