.rounded-box {
    @include radius-default;
    box-shadow: 0 0.1rem 0.8rem $color-13;
    padding: 2rem;
    background: #fff;
    margin-bottom: 3rem;

    &.tall {
        padding: 1rem 2rem !important;
    }

    &.big {
        padding: 3rem 2rem;
    }

    &.bg {
        background: $color-3;
    }

    &.flexed {
        display: flex;
    }

    &.absolute {
        min-width: 70rem;
        position: relative;
        overflow: visible;
        margin-top: 0;

        &.fixed-h {
            height: 65rem;
        }
    }

    &.exportable {
        height: 50.5rem;

        .chart-legend-wrapper {
            right: unset;
            left: 2rem;
            transform: translateY(50%);
        }

        button {
            position: absolute;
            top: 1rem;
            right: 2rem;
            bottom: initial;
            z-index: 20;

            @include bp(lg) {
                bottom: 0.5rem;
                top: unset;
            }
        }
    }

    .rounded-box-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.2rem;

        &.column {
            flex-direction: column;
        }
    }

    .rounded-box-title {
        font-family: "R-bold";
        font-size: $medium;
        margin-bottom: 0.8rem;
    }

    .rounded-box-subtitle {
        display: block;
        font-size: $small;
    }

    .header-break {
        flex-basis: 100%;
        height: 0;
    }

    .pie-wrapper,
    .chart-wrapper {
        height: 30rem;
        position: relative;

        &.bigger {
            width: 150%;
            transform: translateX(-15%);

            &.special {
                @include bp(xl) {
                    width: unset;
                    transform: unset;
                }
            }

            .pie-data {
                font-size: 3rem;
            }
        }

        svg {
            text {
                display: none;
            }
        }

        .pie-data {
            position: absolute;
            left: 50%;
            top: 48%;
            transform: translate(-50%, -80%);
            font-size: $big;
            font-family: "R-regular";
        }
    }

    .info {
        font-size: $medium-large;
    }

    .number {
        font-family: "R-medium";
    }

    .modal-content {
        background-color: $color-3 !important;
    }
}
