.main-menu {
    background-color: $color-1;
    width: 30rem;
    padding: 3rem;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;

    .logo {
        display: block;
        margin: 0 auto 10rem;
        height: 5.6rem;
    }

    .mini-logo{
        display: none;
        height: 5.6rem;
    }

    .wrapper-voice-menu {
        height: calc(100% - 15.6rem);
        display: flex;
        flex-direction: column;
    }

    .wrapper-appstore {
        margin-top: auto;
    }

    .collapsed-appstore{
        display: none;
    }

    &.collapsed{
        padding: 2.2rem;
        width: 10rem;

  

        .wrapper-voice-menu {
            height: calc(100% - 10rem);
            padding-right: 0.5rem;
            overflow-y: hidden;
        
        }

        padding-right: 1.7rem;
        &:hover{
            .wrapper-voice-menu{
                overflow-y: auto;
            }
        }

        .wrapper-appstore{
            display: none;
        }

        .collapsed-appstore{
            display: block;
            margin-top: auto;
            margin-bottom: 3rem;

            img{
                width: 2rem;
            }
        }

        .logo{
            display: none;
        }

        .mini-logo{
            display: block;
            margin-bottom: 10rem;
        }
    }
}
