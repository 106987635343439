.pagination{
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 15rem 2.5rem 0 auto;

    button{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 3.5rem;
        width: 3.5rem;
        border: 0.1rem solid $color-22;
        border-radius: 0.4rem;
        background: #fff;
        margin: 0 0.8rem;

        &.active{
            background: $color-22;
            color: #fff;
        }
    }
}