.patient-list{
    margin: 1.5rem auto;

    .patients-wrapper{
        max-height: 35rem;
        overflow-y: auto;
    }

    &.patient-absolute{
        position: fixed;
        top: 10rem;
        z-index: 20;
    }

    .patient-list-item{
        display: flex;
        align-items: center;

        .avatar{
            margin-right: 1rem;
        }
    }
}

.patient-box{
    padding: 1rem!important;
    cursor: pointer;
}