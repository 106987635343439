.page-header{
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
    flex-wrap: nowrap;

    @include bp(md){
        flex-wrap: wrap;
    }
    
    .breadcrumbs{
        margin-top: 1rem;
    }
    
    .page-actions{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;

        input{
            width: 30rem;
    
        }

        *{
            margin-top: 1rem;
        }

        p{
            margin-right: 1rem;
            font-family: 'R-medium';
        }

        .month-selector{
            border: .2rem solid $color-1;
            padding: 0.15rem 1rem;
            padding-left: 9rem;
            width: unset;
            cursor: pointer;

        }

        &.right{
            margin-left: auto;
        }
    }

    .order-button{
        margin: 0 1.5rem;
        padding: 0.5rem 1.5rem;
        border-radius: .3rem;
        height: 4rem;
        width: 4rem;
        border: .2rem solid $color-1;
        background: #fff;
        color: $color-4;
        cursor: pointer;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        &.up{
            background-image: url(../../images/icons/order-up-white.png);
            
            &.active{
                background-image: url(../../images/icons/order-up.png);
            }
        }

        &.down{
            background-image: url(../../images/icons/order-down-white.png);
            
            &.active{
                background-image: url(../../images/icons/order-down.png);
            }
        }

        &.active{
            background-color: $color-1;
            color: #fff;
        }
    }

    .label{
        color: $color-4;
        opacity: .5;
        white-space: nowrap;
    }
}