.fake-select {
    position: relative;

    font-family: "R-regular" !important;
    background-repeat: no-repeat;
    background: #fff;
    border: 0.2rem solid #31ac7e;
    border-radius: 0.3rem;
    margin: 0 2rem;
    padding: 0.4rem 1rem;
    cursor: pointer;
    width: calc(100% - 2rem);
    user-select: none;

    &.open {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        .fake-options {
        }
    }

    .fake-options {
        position: absolute;
        background: #fff;
        min-width: 100%;
        white-space: nowrap;
        top: 2rem;
        left: -0.2rem;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        box-shadow: 0 0.8rem 0.8rem rgba(20, 46, 110, 0.1);
        z-index: 50;
        border: 0.2rem solid #31ac7e;
        border-top: none;

        li {
            padding: 1rem;
            cursor: pointer;
            border-bottom: 0.1rem solid #31ac7e;
            margin-top: 0;
            font-family: "R-regular";
            font-size: $default-l;

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background: $color-1;
                color: white;
            }
        }
    }
}

.select-arrow {
    position: absolute;
    right: 0.5rem;
    width: 0.8em;
    height: 0.5em;
    background-color: $color-1;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);

    &.top {
        top: 1.5rem;
    }
}

.fake-select {
    .select-arrow {
        transition: all 0.3s;
    }

    &.open {
        .select-arrow {
            transform: translateY(-30%) rotate(180deg);
        }
    }
}
