.caregiver-wrapper{
    display: flex;
    align-items: center;

    .avatar{
        margin-right: 2rem;
    }

    .caregiver-name{
        font-family: 'R-bold';
        font-size: $medium;
    }
}