@charset "UTF-8";
/*------------------------------------*\
    #Mq
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --breakpoint-xxxl: 1900px;
\*------------------------------------*/

@mixin bp($point) {

  @if $point == xxxl {
    @media (max-width: 1900px) { @content; }
  }

  @else if $point == xxlmiddle {
    @media (max-width: 1450px){ @content; }
  }
  @else if $point == xxl {
    @media (max-width: 1300px){ @content; }
  }

  @else if $point == xl {
    @media (max-width: 1200px){ @content; }
  }

  @else if $point == lg {
    @media (max-width: 992px){ @content; }
  }

  @else if $point == md {
    @media (max-width: 768px){ @content; }
  }

   @else if $point == xs {
    @media (min-width: 0px) and (max-width: 575px){ @content; }
  }
   @else if $point == xxs {
    @media (min-width: 0px) and (max-width: 365px){ @content; }
  }

}

@mixin bph($pointH) {
    @if $pointH == xxxl {
      @media (max-height: 900px) { @content; }
    }

    @else if $pointH == xxl {
      @media (max-height: 800px){ @content; }
    }

    @else if $pointH == xl {
      @media (max-height: 700px){ @content; }
    }

    @else if $pointH == lg {
      @media (max-height: 600px){ @content; }
    }

    @else if $pointH == md {
      @media (max-height: 500px){ @content; }
    }

     @else if $pointH == xs {
      @media (max-height: 0px) and (max-width: 400px){ @content; }
    }
}
