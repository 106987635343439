.rbc-calendar {
    min-width: 50rem;
}

.rbc-row-content {
    height: 100%;
}

.rbc-header {
    padding: 3rem 0;
    background: $color-8;
    font-family: "R-regular";
    font-size: $small;
    color: $color-19;
    font-weight: 400;

    &:nth-child(5) {
        background: $color-7;
        color: #fff;
    }
}

.rbc-date-cell {
    text-align: left;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
}

.rbc-day-slot {
    .rbc-events-container {
        margin: 0;

        .rbc-event {
            cursor: pointer;
            padding: 0;
            &:hover {
                background: $color-3;
            }

            .rbc-event-content {
                width: 100%;
                word-wrap: break-word;
                line-height: 1;
                height: fit-content;
                display: flex;
                flex-grow: 1;
                align-items: center;
                justify-content: center;
                height: 100%;

                .display-event {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-grow: 1;
                    height: 100%;
                }
            }
        }
    }
}

.rbc-label {
    &.rbc-time-header-gutter {
        display: none !important;
    }
}

.rbc-time-slot {
    display: none;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
    background-color: transparent;
}

.rbc-event-label {
    display: none;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: $color-1;
}

.rbc-row-segment {
    position: relative;
}

.appointments {
    display: block;
    height: 100%;
    width: 100%;
}
.rbc-appointment-handler {
    position: absolute;
    // background: red;
    top: 0;
    bottom: 0;
    height: 150%;
    width: 100%;
    z-index: 4;
}
.rbc-show-more {
    z-index: 3;
    position: absolute;
    bottom: -2rem;
}
.rbc-event-content {
    background: transparent;
    color: $color-4;
    overflow: visible !important;

    .display-event {
        position: relative;
        display: flex;
        justify-content: center;
        font-family: "R-medium";
        font-size: $small;
        white-space: break-spaces;

        .ph-calendar {
            position: absolute;
            left: 0;
            font-size: $small;
        }
    }
}

.rbc-time-view,
.rbc-month-view {
    border-radius: 0.8rem;
    overflow: hidden;
    box-shadow: 0 0.1rem 0.8rem $color-13;
}

.rbc-toolbar {
    justify-content: flex-end;

    .rbc-btn-group {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .rbc-date-managment {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        height: 6rem;
        background: $color-9;
        user-select: none;

        .rbc-toolbar-label {
            padding: 0 1rem;
            white-space: nowrap;
        }

        svg {
            color: $color-1;
        }
    }

    button {
        width: 15rem;
        height: 6rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: $color-9;
        color: $color-5;

        &.active {
            background: #fff;
            box-shadow: 0 0.1rem 0.8rem 0 rgba(20, 46, 110, 0.1);
        }

        &:hover {
            background: #fff;
        }
    }
}
