.patient-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 2rem 0 2rem 2rem;
    border-radius: 0.8rem;
    margin-bottom: 3rem;
    cursor: pointer;
    @include bp(xl) {
        flex-wrap: wrap;
        justify-content: center;
        padding: 2rem;
    }

    .user-wrapper {
        margin-right: 3rem;
        width: calc(25% - 3rem);
        @include bp(xl) {
            width: 100%;
            margin-right: 0;
        }
    }

    .caregivers {
        width: 15%;
        @include bp(xl) {
            width: 100%;
        }
        @include bp(lg) {
            display: flex;
            flex-wrap: wrap;

            .caregiver-wrapper {
                margin: 1rem 2rem 1rem 0;

                .avatar {
                    margin-right: 0.5rem;
                }
            }
        }
    }

    .appointment {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        border: none;
        width: 15%;
        margin-left: auto;
        padding: 2.5rem 1rem;

        img {
            height: 3.5rem;
            width: 3.5rem;
        }
        @include bp(xl) {
            width: unset;
            margin-left: unset;
        }
    }

    .appointment-label {
        font-family: "R-black";
        color: $color-5;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .activities {
        // display: grid;
        // grid-template-rows: 1fr 1fr 1fr;
        // grid-template-columns: 15rem 15rem;
        display: flex;
        flex-flow: row wrap;
        width: 50rem;
        margin: -2rem 0;
        width: 45%;

        .activity-compact {
            width: 33.3%;
            @include bp(xl) {
                width: 50%;
            }
            @include bp(xs) {
                width: 100%;
            }
        }
        @include bp(xxlmiddle) {

            .activity-compact {
                padding: 2rem 1rem;
            }
        }
        @include bp(xl) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: calc(100% + 4rem);
            margin: 0 -2rem -2rem;
        }
    }
}
