.user-menu {
    display: inline-flex;
    align-items: center;

    cursor: pointer;

    span {
        margin-right: 1.5rem;
    }

    img {
        width: 3.5rem;
    }
}
