h1 {
    font-family: 'R-bold';
    font-size: $big;
    color: inherit;
    margin-bottom: 2rem;
    line-height: 1;
}

h2 {
    font-family: 'R-bold';
    font-size: $big;
    color: inherit;
    margin-bottom: 1rem;
    line-height: 1;
}

h3 {
    font-family: 'R-bold';
    font-size: $large;
    color: inherit;
    margin-bottom: 0;
    line-height: 1;
}

h4 {
    font-family: 'R-bold';
    font-size: $large;
    color: inherit;
    margin-bottom: 1rem;
}

h5 {
    font-family: 'R-bold';
    font-size: $medium;
    color: inherit;
    margin-bottom: 1rem;
}

h6 {
    font-family: 'R-bold';
    font-size: $small;
    color: inherit;
    margin-bottom: 0;
}

thead {
    th {
        font-family: 'R-bold';
        font-size: $medium;
        color: inherit;
    }
}

strong {
    font-family: 'R-bold';
}


.filter-number{
    color: $color-31;
  }