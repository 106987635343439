/*------------------------------------*\
    COLORS
\*------------------------------------*/
// Green
$color-1: #31ac7e;
// Deep Blue
$color-2: #0c0a3d;
// Ultra Light Gray
$color-3: #f8f8f8;
// Black
$color-4: #333333;
// Violet
$color-5: #5865c2;
// Red
$color-6: #fa5b65;
// Gray
$color-7: #414d55;
// Light Azure
$color-8: #f1f5f8;
// Ultra Light Azure
$color-9: #fafbfd;
// Light Green
$color-10: #5fd6a9;
// Light Gray
$color-11: #dddddd;
// Gray Transparent
$color-12: rgba(193, 207, 217, 0.2);
// Shadow
$color-13: rgba(20, 46, 110, 0.1);
// Yellow
$color-14: #ffcd54;
// Deep Green
$color-15: #489f74;
// Light Gray
$color-16: #a2c0d4;
// Dark Gray
$color-17: #636d73;
// Light Green
$color-18: #f0faf8;
// Darker Gray
$color-19: #4d4f5c;
// Lighter Gray
$color-20: #e9e9f0;
// Transparent Gray
$color-21: #4d4f5c7d;
// Green
$color-22: #2ba273;
// Gray
$color-23: #e8e8e8;
// Dark Gray 2
$color-24: #b2b2b2;
// purple
$color-25: #be23da;
// lime green
$color-26: #9bd919;
// Dark Gray 3
$color-27: #a3a6b4;
// Light Gray 2
$color-28: #f5f7f9;
// Light Gray 3
$color-29: #e8e9ec;

$color-30: #A6B1C2;

$color-31: #3B519C;

// Male color
$male-color: #AFD1FF;
// Female color
$female-color: #FFAFFC;
// Other color
$other-color: #FFCD54;

/*------------------------------------*\
    FONT SIZE
\*------------------------------------*/

$bigger: 5rem;
$big: 4rem;
$big-medium: 3.5rem;
$big: 2.8rem;
$large: 2.5rem;
$medium-large: 2.3rem;
$medium: 1.8rem;
$default-l: 1.6rem;
$default: 1.5rem;
$small: 1.3rem;
$small-extra: 1.1rem;
$smaller: .8rem;

/*------------------------------------*\
    MEASURES
\*------------------------------------*/
$m-big: 5rem;
$m-large: 4rem;
$m-notable: 3rem;
$m-middle: 2rem;
$m-base: 1rem;
