.chart-legend-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    top: 1rem;
    right: 2rem;
    z-index: 11;

    .legend-element {
        position: relative;
        display: flex;
        height: 2rem;
        align-items: center;
        margin-left: 1.5rem;
        cursor: pointer;
        padding: 2rem 0;

        &.active {
            p{
                border-bottom: 0.1rem solid $color-4;
            }
        }

        .legend-square {
            height: 1rem;
            width: 1rem;
            margin-right: 0.5rem;
            background: $color-1;
        }

        &:nth-child(1) {
            .legend-square {
                background-color: $color-4;
            }
        }

        &:nth-child(2) {
            .legend-square {
                background-color: $color-6;
            }
        }

        &:nth-child(4) {
            .legend-square {
                background-color: $color-17;
            }
        }

        &:nth-child(5) {
            .legend-square {
                background-color: $color-5;
            }
        }
    }
}

.chart-box {
    height: 45rem;

    &.no-limit {
        height: unset;
    }

    .button {
        &.more-detail {
            position: absolute;
            bottom: 2rem;
        }
    }

    .absolute-chart,
    .regular-chart {
        position: absolute;
        height: calc(100% - 10rem);
        width: calc(100% - 4rem);
        top: 2rem;
        left: 2rem;
        z-index: 2;

        &.chart-active {
            z-index: 4;
        }

        &.special {
            z-index: 5;
        }

        &.relative {
            position: relative;
        }
    }

    .regular-chart {
        z-index: 1;
    }

    .chart-confront {
        display: flex;
        position: absolute;
        align-items: center;
        right: 2rem;
        z-index: 10;

        select {
            cursor: pointer;
        }
    }

    &.comparative-chart {
        padding: 0;

        .absolute-chart,
        .regular-chart {
            width: calc(100% + 10rem);
            left: -5rem;
        }

        .rounded-box {
            box-shadow: unset;
            padding: 0;
        }
    }
}
