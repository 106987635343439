.scrollable-calendar {
    height: 49rem;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 -2rem;
    position: relative;

    .date-element {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0.7rem 2rem;
        background-color: $color-18;
        margin: 0 0.1rem 2rem 0.1rem;
        border-radius: 1rem;

        .day {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 5rem;
            align-items: center;

            .week-day {
                margin-bottom: 0.5rem;
                font-family: "R-medium";
            }

            .week-day-name {
                font-size: $small-extra;
            }
        }

        .speical-day {
            margin-left: 2rem;
            color: $color-1;
        }

        &.today {
            background-color: $color-1;

            .day {
                color: #fff;
            }

            .speical-day {
                color: #fff;
            }
        }
    }

    .appointmens-container {
        .appointment {
            margin: 0 2rem;
            border-color: $color-1;

            &:last-child {
                border: none;
            }

            &:first-child {
                padding-top: 0;
            }
        }
    }
    .empty {
        text-align: center;
        margin-bottom: 2rem;
    }
}
