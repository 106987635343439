.notification-wrapper{
    text-align: center;

    .notification-title{
        font-family: 'R-regular';
        font-size: $medium;
        margin-bottom: 1.8rem;
    }

    .notification{
        text-align: left;
        padding: 1rem 0;
        border-top: 0.1rem solid $color-8;
        cursor: pointer;
        color: $color-24;
        
        &.unread{
            color: $color-19;
        }

        &:hover{
            background: $color-9;
        }
    }

    .notification-sender{
        font-family: "R-black";
    }

    .notification-time{
        font-size: $small-extra;
        color: $color-17;
    }
}