.notification-row{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    border-top: .1rem solid rgb(235, 235, 235);
    padding: 1rem 0;

    .notification-body{
        font-size: 1.3rem;
        text-align: left;
        margin-left: 2rem;
    }

    .notification-actions{
        display: flex;
        margin-left: auto;
        .button{
            margin: 0;
            padding: 0.3rem 1rem;

            &:last-child{
                margin-left: 1rem;
            }
        }
    }
}