.selectable-section{
    display: flex;

    .section-calendar{
        width: calc(100% - 2rem);
        margin-left: 2rem;
    }

    .rbc-calendar{
        min-width: unset;
        .rbc-toolbar button{
            width: 11rem;
            height: 4rem;
        }

        .rbc-toolbar{
            .rbc-date-managment{
                width: 18rem;
                height: 4rem;
            }
        } 

    }

    .activity-compact{
        justify-content: flex-start;
        width: 17rem;
        margin: 1rem 0;
        padding: 1rem;
        border: 0.1rem solid $color-22;
        border-radius: 0.4rem;

        &.unselected{
            border-color: #fff;
            cursor: pointer;
        }

    }

    .section-info{
        max-height: 45rem;
        width: 23rem;
        overflow: auto;
    }

    &.distribution{
        flex-flow: row wrap;

        .section-calendar{
            width: calc(100% - 25rem);

            &.last{
                margin-left: auto;
            }
        }
    }
}