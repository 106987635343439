.item-menu {
    position: relative;
    padding: 1.5rem 1.8rem;
    font-size: $medium;
    font-family: "R-bold";
    background-color: #ffffff;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
    @include transition-default;
    cursor: pointer;

    &:hover,
    &.active {
        @include transition-default;
        background-color: $color-5;
        color: #ffffff;

        .icon-img {
            display: none;

            &.icon-w {
                display: block;
            }
        }
    }

    .icon-img {
        margin-right: 2rem;
        width: 2rem;
        height: 2rem;

        &.icon-w {
            display: none;
        }
    }

    .collapsed & {
        span {
            display: none;
        }
    }
    a {
        display: inherit;
    }
}
