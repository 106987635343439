.reduce-menu {
    width: 4rem;
    height: 4rem;
    flex: 0 0 4rem;
    border-radius: 0.2rem;
    background: url("../../images/icons/arrow-left.png") center no-repeat;
    background-color: #ffffff;
    background-size: 2rem;
    border: 0.1rem solid $color-1;
    box-shadow: 0 0.6rem 2rem $color-12;

    &:hover {
        cursor: pointer;
    }

    &.collapsed{
        transform: scaleX(-1);
    }
}
