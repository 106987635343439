.breadcrumbs{
    font-family: 'R-medium';
    font-size: $big;
    color: $color-4;
    cursor: pointer;
    
    &:hover{
        text-decoration: underline;
    }

    &.current{
        opacity: 0.3;
        font-size: $medium;
        &:hover{
            text-decoration: none;
        }
    }
}

.breadcrumbs-wrapper{
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-flow: row wrap;
    .breadcrumb-separator{
        margin: 0 0.5rem;
    }
}