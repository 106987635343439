.filter-check{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    border: solid 0.2rem $color-24;
    border-radius: 0.6rem;
    cursor: pointer;


    svg{
        visibility: hidden;
        color: $color-1;
    }

    &.checked{
        border: solid 0.2rem $color-1;

        svg{
            visibility: visible;
        }
    }
}