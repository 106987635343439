.legend-wrapper {
  display: flex;
  flex-wrap: wrap;

  .legend-item {
    position: relative;
    margin: 1rem;
    padding-left: 4rem;
    white-space: nowrap;
    font-family: "R-Regular";
    font-size: $small-extra;
    color: $color-30;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &:before {
      content: "";
      height: 0.5rem;
      width: 2.5rem;
      border-radius: 0.3rem;
      background-color: $male-color;
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(0.55rem) scale(1);
      transition: transform 0.3s;
    }

    &.female {
      &::before {
        background-color: $female-color;
      }
    }
    &.other {
      &::before {
        background-color: $other-color;
      }
    }

    &:hover,
    &.selected {
      text-decoration: underline;

      &:before {
        transform: translateY(0.55rem) scale(1.3);
      }
    }
  }
}
