.green-box{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8rem;
    border: 0.1rem solid $color-1;
    border-radius: 0.3rem;
    cursor: pointer;

    svg{
        font-size: $medium-large;
        margin-right: 1rem;
    }
}