.modal-backdrop {
    &.show {
        opacity: 0.7;
    }
}

.modal {
    background-color: $color-10;
    z-index: 99999;

    .modal-md {
        max-width: 64rem;
    }

    .modal-dialog {
        @include bp(xs) {
            width: calc(100% - 2rem) !important;
            left: 1rem;
            max-width: 100%;
        }
    }

    &.dark {
        background-color: $color-1;
    }

    .close {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        /*background: url("../../images/icons/close-w.png") no-repeat center, $color-9;*/
        position: fixed;
        right: 2rem;
        top: 2rem;
        opacity: 1;
        @include bp(md) {
            top: 1rem;
            left: 1rem;
        }

        span {
            display: none;
        }
    }

    .modal-content {
        @include radius-default;
        overflow: visible;
        border: 0;

        .modal-header {
            border: 0;
            padding: 2rem;
            justify-content: center;

            .modal-title {
                font-size: $large;
                font-family: "P-bold";
                text-align: center;
                text-transform: uppercase;
            }
        }

        .modal-body {
            min-height: 8rem;
            padding: 0;
            margin: 0 2rem;

            p {
                font-size: $small;
                @include bp(md) {
                    font-size: $large;
                }
            }
        }

        .modal-footer {
            padding: 2rem;
            border-top: 0.1rem solid $color-2;
        }
    }

    .spinner-loader {
        position: relative;
        top: calc(50% - 60px);
        left: calc(50% - 30px);
    }
}
