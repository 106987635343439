.accept-wrapper{
    display: flex;
    align-items: center;

    .buttons{
        text-align: right;
        margin-left: 3rem;

        button{
            width: 100%;
            margin: 1rem 0;
        }
    }

    @include bp(xs){
        flex-direction: column;

        .buttons{
            width: 100%;
            display: flex;
            justify-content: space-between;

            button{
                width: unset;
            }
        }
    }
}