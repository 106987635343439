.login-wrapper{
    background: #f0faf8;
    height: 100vh;
    width: 100vw;

    .rounded-box{
        background: #fff;
        text-align: center;
        margin-top: 8rem;
    }

    .login-logo{
        width: 18rem;
        height: 6rem;
        margin: 5rem auto 3rem auto;
        background-image: url(../../images/layout/logo-green.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    
    .subtitle{
        font-family: 'R-medium';
        margin-bottom: 0.5rem;
    }

    .closing{
        margin-top: 2rem;
    }
}